/**
 * @file Provides uploading capability in the frontend.
 *
 * Will try resumable uploads (direct to Google Cloud Storage) first, then
 * fallback to Beethoven uploads.
 *
 */
import ajax from '@@/bits/ajax'
import { trackEvent } from '@@/bits/analytics'
import { captureMessage } from '@@/bits/error_tracker'
import { getEmbeddedUrl, getName } from '@@/bits/file_handler'
import {
  cancelResumableUpload,
  createResumableUpload,
  INVALID_RESUMABLE_SESSION_URI,
  startResumableUpload,
  subscribeUploadEvent,
  supportsGcsResumableUploads,
} from '@@/bits/gcs_resumable_upload'
import { $ } from '@@/bits/jquery'
import EventEmitter from 'events'
import { defer } from 'lodash-es'

let uploadsInProgress = []
const FILE_TOO_LARGE_ERROR = 'FILE_TOO_LARGE_ERROR'
const ONE_MB = 1024 * 1024
const MAX_FILE_UPLOAD_BYTES_SIZE = 31457280

const trackError = function (label, context = {}) {
  trackEvent('Uploads', 'Upload failed', label)
  context['fileType'] = label
  captureMessage('UploadError', { context })
}

const trackSuccess = function (label) {
  trackEvent('Uploads', 'Upload succeeded', label)
}

class UploadJob extends EventEmitter {
  constructor(file, options = {}) {
    super()
    this.file = file
    this.options = options
  }

  maxFileSize() {
    if (this.options.maxFileSize) {
      // We give 1MB extra. Psychologically, 25.5MB is 25MB, not 26MB.
      return this.options.maxFileSize + ONE_MB
    }
    return this.options.maxFileSize
  }

  cancel() {
    if (this.xhr1) this.xhr1.abort()
    if (this.xhr2) this.xhr2.abort()
    if (this.resumableUpload) cancelResumableUpload(this.resumableUpload)
    if (this.completionXhr) this.completionXhr.abort()
    if (this.trimXhr) this.trimXhr.abort()
    this.emit('cancel')
  }

  perform() {
    const maxFileSizeBytes = this.maxFileSize()
    if (maxFileSizeBytes && this.file.size > maxFileSizeBytes) {
      trackEvent('Uploads', 'Upload failed due to big filesize', this.file.type, this.file.size)
      this._reject({ error: FILE_TOO_LARGE_ERROR })
    } else {
      // Sometimes, a file can just be a holder of a url. (e.g. gdoc file)
      // It so, try to get the URL and just return that.
      getEmbeddedUrl(this.file).then(this._resolve.bind(this), this._ajax1.bind(this, this._useResumable()))
    }
  }

  _progress(e, timeStart) {
    const loadRatio = e.loaded / e.total
    // const timeNow = new Date()
    // const timeElapsed = timeNow - timeStart
    // const expectedTimeToProcess = 3000 //3 seconds
    // const loadRatio = e.loaded / e.total
    // const expectedTimeToUpload = timeElapsed / loadRatio
    // const expectedTimeToUploadAndProcess = expectedTimeToUpload + expectedTimeToProcess;
    // const actualProgress = timeElapsed/expectedTimeToUploadAndProcess
    this.emit('progress', loadRatio)
  }

  _useResumable() {
    return supportsGcsResumableUploads()
  }

  _resolve(obj) {
    this.emit('done', obj)
  }

  _reject(obj) {
    this.emit('error', obj)
  }

  // We will request to do resumable uploads but the backend
  // can override that decision.
  _ajax1(requestResumableEndpoint = false) {
    const self = this
    this.xhr1 = ajax({
      url: '/media/upload_attributes.json',
      dataType: 'json',
      type: 'GET',
      cache: false,
      sendOauthToken: true,
      data: {
        unique: +new Date(),
        fileSize: self.file.size,
        filename: self.file.name,
        contentType: self.file.type,
        resumable: requestResumableEndpoint,
        maxFileSize: this.maxFileSize(),
      },
      error(responseData, textStatus) {
        if (textStatus == 'abort') return
        trackError('ajax1', {
          statusCode: responseData.status,
          readyState: responseData.readyState,
        })
        self._reject(responseData)
      },
      success(attributes) {
        const { resumable } = attributes
        if (resumable) {
          self._resumable(attributes)
        } else {
          self._ajax2(attributes)
        }
      },
    })
  }

  _ajax2(attributes) {
    const timeStart = new Date()
    const self = this
    const formData = new FormData()
    const name = getName(this.file)
    const originalFilename = this.file.name ? this.file.name : name

    const fieldsOverrides = {}
    if (this.options.maxFileSize) {
      fieldsOverrides.max_file_size = this.options.maxFileSize + 1048576
      fieldsOverrides.transcode = true
    }
    const fields = Object.assign({}, attributes.fields, fieldsOverrides)
    Object.entries(fields).forEach((x) => {
      if (x[0] != 'key') formData.append(x[0], x[1])
    })
    formData.append('key', [attributes.fields.key, name].join('/'))
    formData.append('Content-Type', this.file.type)
    formData.append('file_size', this.file.size)
    formData.append('title', originalFilename)
    formData.append('file', this.file, name)

    this.xhr2 = ajax({
      url: attributes.url,
      crossDomain: true,
      type: 'POST',
      data: formData,
      cache: false,
      contentType: false,
      processData: false,
      dataType: 'json',
      xhr() {
        const x = $.ajaxSettings.xhr()
        if (x.upload) {
          x.upload.addEventListener('progress', (e) => {
            if (e.lengthComputable) {
              self._progress(e, timeStart)
            }
          })
        }
        return x
      },
      error(responseData, textStatus) {
        if (textStatus == 'abort') return
        trackError(self.file.type, {
          statusCode: responseData.status,
          userId: fields.uploader_id,
          filename: self.file.name,
          filsSize: self.file.size,
          maxFileSize: fields.max_file_size || 'None',
          resuamble: false,
          readyState: responseData.readyState,
          responseText: responseData.responseText,
          responseXml: responseData.responseXml, //could be returned by nginx/lb
        })
        self._reject(responseData)
      },
      success(responseData) {
        trackSuccess(self.file.type)
        self._resolve(responseData)
      },
    })
  }

  _resumable(attributes) {
    const timeStart = new Date()
    const self = this
    const file = self.file
    const { fields } = attributes
    const { completion_url: completionUrl } = fields

    // Standard way of handling error and falling back
    const trackErrorAndFallback = (error) => {
      trackError(file.type, {
        action: 'gcsResumableUpload',
        message: error.message,
        type: 'GCS Resumable Upload',
        fileSize: file.size,
        filename: file.name,
        userId: fields.uploader_id,
        maxFileSize: fields.max_file_size || 'None',
      })

      // Fallback to the older way
      self._ajax1(false)
    }

    // 1. Check that resumable URL is successfully created
    const sanityCheck = (gru) => {
      const { resumableSessionUri } = gru
      // Check if we initiated the session successfully
      if (!resumableSessionUri || resumableSessionUri === INVALID_RESUMABLE_SESSION_URI) {
        throw new Error('Cannot perform resumable upload')
      }

      // Assign for later user e.g. when cancelling the upload
      self.resumableUpload = gru
      return gru
    }

    // 2. Subscribe to events. Know when things fail, when there's progress, when an
    // upload is complete.
    const subscribeToUploadEvents = (gru) => {
      subscribeUploadEvent(gru, 'error', (gru, error) => {
        trackErrorAndFallback(error)
      })
      subscribeUploadEvent(gru, 'progress', (gru, fractionPercentage) => {
        self._progress({ loaded: fractionPercentage, total: 1.0 }, timeStart)
      })
      subscribeUploadEvent(gru, 'completion', async () => {
        if (self.isVideoFile && self.options.trimVideoAfterUpload) {
          // TODO: This should be asynchronous!
          const trimResult = await self._trimVideoAfterUpload(fields.uploaded_url, {
            start: self.options.trimVideoAfterUpload.start,
            end: self.options.trimVideoAfterUpload.end,
            trimToken: fields.trim_token,
            // outputMimeType: 'video/mp4', // Always transcode to mp4
          })
          // Video is trimmed and transcoded so url or extension may differ from original upload
          fields.uploaded_url = trimResult.url
          fields.filename = trimResult.filename
        }
        self._completeResumable(completionUrl, fields)
      })
      return gru
    }

    createResumableUpload(file, attributes)
      .then(sanityCheck)
      .then(subscribeToUploadEvents)
      .then(startResumableUpload)
      .catch(trackErrorAndFallback)
  }

  _completeResumable(completionUrl, fields) {
    const self = this
    const file = self.file
    this.completionXhr = ajax({
      url: completionUrl,
      crossDomain: true,
      dataType: 'json',
      type: 'POST',
      cache: false,
      data: {
        completeResumable: true,
        ...fields,
      },
      error(responseData, textStatus) {
        if (textStatus == 'abort') return
        trackError(self.file.type, {
          action: 'completeResumable',
          userId: fields.uploader_id,
          filename: file.name,
          maxFileSize: fields.max_file_size || 'None',
          fileSize: file.size,
          statusCode: responseData.status,
          readyState: responseData.readyState,
        })
        // try with non-resumable
        self._ajax1(false)
      },
      success(responseData) {
        self._resolve(responseData)
        trackSuccess(file.type)
      },
    })
  }

  // #region Video trimming
  async _trimVideoAfterUpload(uploadedUrl, trimOptions) {
    return new Promise((resolve, reject) => {
      this.trimXhr = ajax({
        url: `/beethoven-v2/video/trim`,
        dataType: 'json',
        type: 'POST',
        headers: {
          Authorization: `Bearer ${trimOptions.trimToken}`,
        },
        data: {
          url: uploadedUrl,
          start: trimOptions.start,
          end: trimOptions.end,
          outputMimeType: trimOptions.outputMimeType,
        },
        cache: false,
        timeout: 5 * 60 * 1000, // 5 minutes
        error(responseData) {
          reject(responseData)
        },
        success(responseData) {
          // responseData: { url: <url of trimmed video> }
          resolve(responseData)
        },
      })
    })
  }

  get isVideoFile() {
    return this.file.type.startsWith('video/')
  }
  // #endregion
}

const startUpload = function (file, options = {}) {
  const uploadJob = new UploadJob(file, options)
  uploadJob.on('done', (result) => {
    uploadsInProgress = uploadsInProgress.filter((x) => x != uploadJob)
  })
  uploadJob.on('error', (error) => {
    uploadsInProgress = uploadsInProgress.filter((x) => x != uploadJob)
  })
  uploadJob.on('cancel', (error) => {
    uploadsInProgress = uploadsInProgress.filter((x) => x != uploadJob)
  })
  uploadsInProgress.push(uploadJob)
  defer(() => uploadJob.perform())
  return uploadJob
}

const stopUpload = function (file) {
  const uploadJob = uploadsInProgress.find((x) => x.file == file)
  if (uploadJob) {
    uploadJob.cancel()
  }
}

export { FILE_TOO_LARGE_ERROR, MAX_FILE_UPLOAD_BYTES_SIZE, startUpload, stopUpload, UploadJob }
